@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oregano&display=swap');

html {
  position: relative;
  min-height: 100%;
  /* overflow: hidden; Hide scrollbars */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

body {
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
      width: 100%;
  }
  margin: 0 0 150px;
}

.container {
  margin-inline: auto;
  width: min(90%, 70.5rem);
}

header,
section {
  padding: 4rem 0;
  @media (min-width: 40em) {
    header,
    section {
      padding: 7rem 0;
    }
  }
}

@media (min-width: 40em) {
  .split {
    flex-direction: row;
  }

  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
}

#root {
  height: 100%;
}

#content-wrap {
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  padding: 0;
  font-family: 'DM Sans', regular;
  font-size: 1.2rem;
  color: white;
  background-color: white;
}

#header {
  position: sticky;
  top: 0;
  padding-right: 24px;
  padding-left: 24px;
}

.brand-logo-text {
  font-family: 'Oregano';
  color: #9E829C;
  letter-spacing: 1px;
  font-size: 1.5rem;
}

#footer {
  position: absolute;
  width: 100%;
  height: 150px;
  left: 0;
  bottom: 0;
  background: black;
  color: white;
  padding: 3em;
  margin-top: auto;
}

.div-about-me {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-top: 8px;
}

.div-about-me-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.about-me-text {
  width: 500px;
  height: auto;
  padding-right: 24px;
  text-align: left;
  color: #2A2D34;
}

.about-me-image {
  width: auto;
  height: 300px;
  display: flex;
  flex-direction: column;
  float: right;
}

.div-social-media-image {
  text-align: center;
}

.social-media-image {
  margin-right: 32px;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
  font-size: 16px;
}

::selection {
  background: blue;
  color: white;
}

a {
  color: #545454;
  text-decoration: none;
}

/* a:focus, 
a:hover {
  color: #8b8b8b;
} */

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: -webkit-sticky;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
}

.a-hover {
  color: pink;
}

.canvas > div {
  z-index: 10000000;
}

.vintage {
  padding: 10px;
  text-align: center;
  color: crimson;
  /* background-image: url(vintage_bg.jpg); */
}

.instagram_feed_container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}

.instagram_feed_container > div {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
}

.instagram_feed1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.homepage {
  background-color: white;
}

.brand-text-color {
  color: #9E829C;
}

.color-nav {
  background-color: #FFFFFF;
}

.nav-text-color {
  color: #252627;
}

.nav-text-color:hover {
  color: #9E829C;
}

.outer_circle {
  padding: 5px; border-radius: 50%; display: inline-block;
  background: #9E829C;
  background: -webkit-linear-gradient(#9E829C, white);
  background: -o-linear-gradient(#9E829C, white);
  background: -moz-linear-gradient(#9E829C, white);
  background: linear-gradient(#9E829C, white);
}

.inner_circle {
  background: white;
  width: 300px;
  height: 300px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.div-home {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 8px;
}

.div-home-gradient {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}

.distribute_horizontally {
  display: flex;
  justify-content: space-around;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0px;
}

.distribute_vertically {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 0px;
}

.margin-at-top {
  margin-top: 10%;
}

.badge-alignment {
  width: 20;
  height: 20;
}

.inner_circle_second {
  background: white;
  width: 250px;
  height: 250px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.div-contact-form {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.contact-form {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message-name, .message-email, .message {
  text-align: start;
  font-size: 16px;
  color: black;
  padding: 12px;
}

.form-category {
  padding: 16px;
}

.send-container {
  background-color: white;
}

.contact-input {
  width: 450px;
  height: 50px;
  background-color: white;
  border: 2px solid #9E829C;
  border-radius: 5px;
  color: #252627;
}

.send-button {
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  color: #252627;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
}

.text-middle-cta {
  text-align: center;
  color: black;
  text-decoration: none;
  font-size: 18px;
}

.text-middle-white {
  text-align: center;
  color: white;
  font-size: 24px;
}

.heading-2 {
  text-align: center;
  color: black;
  text-transform: uppercase;
  font-size: 18px;
}

.circle_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

hr.divider {
  border-top: 2px solid rgb(231, 226, 226);
  width: 150px;
}

hr.divider-white {
  border-top: 1px solid white;
  width: 150px;
}

.div-hover:visited, .div-hover:hover, .div-hover:active {
  background-color: #9E829C;
  border-radius: 5px;
  text-decoration: underline;
  text-decoration-color: #9E829C;
  cursor: pointer;
}

.div-hover {
  padding: 5px;
}

.a-hover {
  text-decoration: none;
}

.div-welcome {
  width: auto;
  height: inherit;
  background-color: #DCD0DB;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
}

.welcome-text {
  text-align: start;
  color: black;
  font-size: 16px;
}

.div-welcome-text {
  width: 50%;
}

.welcome-image {
  width: inherit;
  height: inherit;
  padding: 20px;
}

.div-welcome-image {
  width: 50%;
  align-items: flex-end;
  justify-content: end;
  display: flex;
  flex-direction: row;
}

.div-connect-with-me {
  margin-top: 20px;
}

.div-what-i-do {
  margin-top: 20px;
}

.align-items-center-in-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
}

.astronomy-calendar {
  border: none;
  width:860px;
  height:759px;
  overflow: hidden;
}

.text-color-dark {
  color: #2A2D34;
}

.favicon-text-font {
  color: #222;
}

.text-body {
  text-align: start;
  color: black;
  font-size: 16px;
}

.text-body-center {
  text-align: center;
  color: black;
  font-size: 16px;
}